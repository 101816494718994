import React from "react"
import PropTypes from "prop-types"
import COMPONENTS from "utils/componentConstants"
import SECTIONS from "utils/sectionConstants"
import SplitSection from "components/sections/splitSection"
import Wrapper from "components/sections/wrapper"
import RichText, { RichTextProps } from "components/elements/richText"
const WholeChildModelPageTemplate = ({ sections }) =>
  sections.map((section, i) => {
    switch (section.__typename) {
      case COMPONENTS.SplitContent:
        return <SplitSection {...section} className="inner-content" key={i} />
      case SECTIONS.WrapperSection:
        return <Wrapper {...section} className="full" />
      case SECTIONS.ContentfulSectionRichText:
        return <RichText {...section} className="section-text" key={i} />

      default:
        return null
    }
  })

WholeChildModelPageTemplate.props = {
  sections: PropTypes.arrayOf(
    PropTypes.oneOfType(
      [PropTypes.shape(SplitSection.props)],
      PropTypes.shape(RichTextProps)
    )
  ),
  slug: PropTypes.string,
  title: PropTypes.string,
}

WholeChildModelPageTemplate.propTypes = WholeChildModelPageTemplate.props

export default WholeChildModelPageTemplate
